import Page from '../components/Page';
import Link from 'next/link';
import Image from 'next/image';
import api from '../utils/api';
import Container from '../components/Container';
import {
  IImport,
  IPaginatedRes,
  fetchShops,
  searchImports,
  fetchCategories,
  IShop,
} from '@mm/common';
import Button from '../components/Button';
import { useEffect, useRef, useState } from 'react';
import ImportCard from '@/components/ImportCard';
import { ICategory } from '@mm/common/categories/models';
import { slugifyImport } from '@mm/common/utils/url';
import { useRouter } from 'next/router';
import Select from 'react-select';
import queryString from 'query-string';
import { sendGAEvent } from '@next/third-parties/google';

export async function getStaticProps() {
  const { shops, shopsEntities } = await fetchShops(api);
  // const locationId = shops.map(shop => `default-${shop.id}`);
  const stats = await api.get(`/v1/stats`);
  const { categories } = await fetchCategories(api)({ tree: true, depth: 1 });
  const imports = await searchImports(api)({
    dealsOnly: true, // process.env.NODE_ENV === 'production',
    limit: 12,
    pinned: false,
    // locationId,
  });
  const pinned = await searchImports(api)({
    // dealsOnly: true, // process.env.NODE_ENV === 'production',
    limit: 8,
    pinnedOnly: true,
    random: true,
    // locationId,
  });

  return {
    props: {
      shopsEntities,
      stats,
      categories,
      imports,
      pinned,
    },
    revalidate: 1 * 60 * 60,
  };
}

export default function Home({
  shopsEntities,
  stats,
  categories,
  imports,
  pinned,
}: {
  shopsEntities: Record<string, IShop>;
  stats: any;
  categories: ICategory[];
  imports: IPaginatedRes<IImport>;
  pinned: IPaginatedRes<IImport>;
}) {
  const promosContainerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const [menuPortalTarget, setMenuPortalTarget] = useState(null);
  useEffect(() => {
    setMenuPortalTarget(document.body);
  }, []);

  const buildHref = ([shop, cats = [], page = 0, query = undefined]) =>
    `${['/shop', ...cats, shop]
      .filter(Boolean)
      .join('/')}?${queryString.stringify({
      query: query || undefined,
      page,
    })}`;

  return (
    <Page
      title="Сравнение на цени и промоции на хранителни продукти, напитки и стоки за бита"
      head={
        <>
          <meta
            name="description"
            content="Сравнение на цени и промоции на хранителни продукти, напитки и стоки за бита от супермаркети в България."
          />
        </>
      }
    >
      <Container>
        <div className="flex flex-row justify-center gap-2">
          <div className="sm:w-1/2">
            <h1 className="text-4xl text-stone-800 sm:mt-14 font-semibold">
              Сравнявай цени и промоции,
              <br />
              пести пари бързо и удобно
            </h1>
            <h2 className="mt-4 mb-6 text-neutral-700">
              MySupermarket е напълно безплатна платформа за сравнение цените на
              хранителните продукти във всички големи вериги супермаркети у нас.
              Планирай пазара и пести с до 30% от месечния си бюджет за храни,
              напитки и стоки за бита.
            </h2>
            <Button
              variant="primary"
              size="lg"
              className="w-full sm:w-auto sm:px-14"
              onClick={() => {
                promosContainerRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              Виж офертите
            </Button>
          </div>
          <div className="hidden sm:flex flex-1 justify-center bg-contain bg-right bg-no-repeat bg-[url(/vegetables2.png)]">
            <Image
              src={require('../public/screen1.png')}
              alt="screen"
              width={250}
              className="border-4 rounded-md"
            />
          </div>
        </div>

        <div className="mt-12 grid grid-cols-3 divide-x">
          <Stat
            value={new Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(stats.imports)}
            label="Оферти"
          />
          <Stat
            value={new Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(100000)}
            label="Потребители"
          />
          {/* <Stat value={shopsLength} label="Магазина" suffix="" /> */}
          <Stat
            value={new Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(stats.brands)}
            label="Марки"
          />
        </div>

        {/* <div className="mt-12 flex flex-row justify-center gap-6">
          {shops.map(shop => (
            <img
              key={shop.id}
              src={shop.imgUrl}
              alt={shop.name}
              className="h-8 hover:grayscale-0"
            />
          ))}
        </div> */}

        {/* Categories */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold my-8">Търси по Категория</h2>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-5">
            {categories.map(cat => (
              <Link
                key={cat.id}
                href={`/shop/${cat.key}`}
                className="p-3 bg-stone-50 hover:bg-secondary-50 rounded"
              >
                {cat.name}
              </Link>
            ))}
          </div>
        </div>

        {/* Promos */}
        <div ref={promosContainerRef} className="mt-10">
          <div className="flex justify-between">
            <h2 className="text-2xl font-bold my-4">Актуални Промоции</h2>
          </div>
          <div className="my-1 sm:my-3 flex gap-3 flex-nowrap overflow-auto pb-4">
            {/* <div className="flex items-center py-2 px-5 bg-stone-100 rounded-full">
            <span className="text-sm font-semibold mr-2">Промоции</span>
            <Toggle
              checked={dealsOnly}
              onChange={e => {
                router.replace(
                  buildHref([e.currentTarget.checked ? 'promocii' : 'all'])
                );
              }}
            />
          </div> */}
            <div className="flex shrink-0 items-center py-2 px-5 bg-stone-100 rounded-full">
              <span className="text-sm font-semibold">
                <Select
                  isMulti
                  placeholder="Магазин"
                  closeMenuOnSelect={false}
                  menuPortalTarget={menuPortalTarget}
                  options={Object.values(shopsEntities).map(shop => ({
                    label: shop.name,
                    value: shop.id,
                  }))}
                  onChange={value => {
                    router.replace(
                      buildHref([value?.map(opt => opt.value).join('+')])
                    );
                  }}
                  styles={{
                    control: styles => ({
                      ...styles,
                      border: 'none',
                      background: 'none',
                      boxShadow: 'none',
                    }),
                    placeholder: styles => ({
                      ...styles,
                      color: 'inherit',
                    }),
                    menu: styles => ({
                      ...styles,
                      zIndex: 100,
                    }),
                  }}
                />
              </span>
            </div>
            <div className="flex shrink-0 items-center py-2 px-5 bg-stone-100 rounded-full">
              <span className="text-sm font-semibold">
                <Select
                  placeholder="Категория"
                  isClearable
                  menuPortalTarget={menuPortalTarget}
                  options={categories.map(parent => ({
                    label: parent.name,
                    options: [
                      {
                        label: `Всички ${parent.name}`,
                        value: parent.id,
                        href: [parent.key],
                      },
                      ...parent.children?.map(child => ({
                        label: child.name,
                        value: child.id,
                        href: [parent.key, child.key],
                      })),
                    ],
                  }))}
                  onChange={value => {
                    router.replace(
                      buildHref([
                        ,
                        // @ts-ignore
                        value?.href || [],
                      ])
                    );
                  }}
                  styles={{
                    control: styles => ({
                      ...styles,
                      border: 'none',
                      background: 'none',
                      boxShadow: 'none',
                    }),
                    placeholder: styles => ({
                      ...styles,
                      color: 'inherit',
                    }),
                    menu: styles => ({
                      ...styles,
                      width: 300,
                      zIndex: 100,
                    }),
                    option: styles => ({
                      ...styles,
                      fontSize: 14,
                    }),
                  }}
                />
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1">
            {imports.docs.map(d => (
              <ImportCard
                as={Link}
                key={d.id}
                href={slugifyImport(d)}
                item={d}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-center my-6">
          <Button variant="primary" as={Link} href="/shop" size="lg">
            Виж още
          </Button>
        </div>

        {pinned.total > 0 && (
          <div className="mt-10">
            <div className="flex justify-between">
              <h2 className="text-2xl font-bold my-4">Избрани Марки</h2>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1">
              {pinned.docs.map(d => (
                <ImportCard
                  key={d.id}
                  as="a"
                  href={`${d.url}?utm_source=cpc&utm_id=mysupermarket`}
                  target={d.pinned ? '_blank' : '_self'}
                  item={d}
                  onClick={e => {
                    sendGAEvent('event', 'select_promotion', {
                      promotion_id: d.shop,
                      items: [
                        {
                          item_id: d.id,
                          item_name: d.name,
                          item_brand: d.brand,
                          price:
                            d.offerings[0].specialPrice || d.offerings[0].price,
                          discount:
                            d.offerings[0].price -
                              d.offerings[0].specialPrice || 0,
                        },
                      ],
                    });
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </Container>

      <div className="text-xs mt-20 text-neutral-400 text-center px-4">
        <a
          href="https://www.flaticon.com/free-icons/vegetable"
          target="_blank"
          title="vegetable icons"
        >
          Vegetable icons created by photo3idea_studio - Flaticon
        </a>
      </div>
    </Page>
  );
}

function Stat({ value, label, suffix = '+' }) {
  return (
    <div className="text-center">
      <span className="font-semibold text-3xl">
        {value}
        {suffix}
      </span>
      <br />
      <span className="text-stone-500">{label}</span>
    </div>
  );
}
